var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Fetch.fit")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Make Fitness your next habbit! ")]),_c('b-overlay',{attrs:{"show":_vm.showOverlay,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","opacity":"0.0","rounded":"sm"}},[_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false : null,"placeholder":"John"},model:{value:(_vm.register.firstName),callback:function ($$v) {_vm.$set(_vm.register, "firstName", $$v)},expression:"register.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"lastname","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false : null,"placeholder":"Doe"},model:{value:(_vm.register.lastName),callback:function ($$v) {_vm.$set(_vm.register, "lastName", $$v)},expression:"register.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Referred by","label-for":"register-referral"}},[_c('b-form-input',{attrs:{"id":"register-referral","disabled":_vm.referedBy ? true : false,"name":"register-referral","placeholder":"Leave empty if you are not sure.."},model:{value:(_vm.referedBy),callback:function ($$v) {_vm.referedBy=$$v},expression:"referedBy"}})],1),_c('b-form-group',{attrs:{"label":"Username","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.register.username),callback:function ($$v) {_vm.$set(_vm.register, "username", $$v)},expression:"register.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.register.email),callback:function ($$v) {_vm.$set(_vm.register, "email", $$v)},expression:"register.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"*********"},model:{value:(_vm.register.password),callback:function ($$v) {_vm.$set(_vm.register, "password", $$v)},expression:"register.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.register.status),callback:function ($$v) {_vm.$set(_vm.register, "status", $$v)},expression:"register.status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{class:_vm.isloading ? 'hidden' : '',attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.isloading}},[(!_vm.isloading)?_c('div',[_c('span',[_vm._v(" Sign up")])]):_vm._e()]),(_vm.isloading)?_c('b-button',{attrs:{"variant":"primary","disabled":"","block":""}},[_c('div',[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])],1)]):_vm._e()],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-1"},[_c('span',[_vm._v("Already have an account? ")])]),_c('b-button',{attrs:{"type":"submit","variant":"outline-primary","block":"","to":{ name: 'login' }}},[_vm._v(" Sign in ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }